.pageContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
}

.contentContainer {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.leftContainer {
  flex: 1;
  @apply bg-grey-900;
  padding: 64px 40px 64px 40px;
}

.rightContainer {
  flex: 1;
  @apply bg-white;
  padding: 32px 20px 32px 20px;
}

@screen lg {
  .pageContainer {
    height: 100%;
  }
  .contentContainer {
    overflow: auto;
  }
}

@screen default-sm {
  .pageContainer {
    justify-content: center;
    align-items: center;
  }
  .contentContainer {
    width: 500px;
  }
}

@screen default-md {
  .contentContainer {
    border-radius: 16px;
  }
  .rightContainer {
    margin-top: 0px;
  }
}

@screen default-lg {
  .contentContainer {
    width: 1000px;
  }
  .pageContainer {
    background-color: #f2f4f7;
  }
  .rightContainer {
    padding: 64px 40px 64px 40px;
  }
}
