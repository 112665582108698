.responsiveText {
    font-weight: 300 !important; 
    text-align: center;
    line-height: 32px !important;
  }
  
  /* For screens smaller than 768px (sm breakpoint) */
  @media (max-width: 768px) {
    .responsiveText {
      font-size: 16px !important; /* Adjusted size for small screens */
      line-height: 24px !important;
    }
  }
  

  
  /* For screens larger than 992px (md breakpoint) */
  @media (min-width: 768px) {
    .responsiveText {
      font-size: 20px !important; /* Larger text for medium and up screens */
    }
  }
  
